<template>
  <div style="padding: 30px">
    <a-list
      item-layout="vertical"
      size="large"
      :pagination="pagination"
      :data-source="listData"
      style="text-align: left"
    >
      <a-list-item slot="renderItem" key="item.title" slot-scope="item">
        <template slot="actions">
          {{ item.date }}
        </template>
        <a-list-item-meta :description="item.description">
          <router-link
            :to="{ name: '文章页', params: { articleid: 123 } }"
            slot="title"
            style="font-size: 26px; color: black"
            >{{ item.title }}</router-link
          >
        </a-list-item-meta>
        {{ item.content }}
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
/* 最新资讯 */
const listData = [
  {
    id: 0,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 3.png"),
    title: "微信小程序，创业者的绝佳选择！！",
    content:
      "淘宝系平台化电商如今越来越难做，而以小程序为入口的移动电商，其去中心化的流量入口让移动电商创业者有了更多的自主性，电商小程序是现在数量最多的小程序之一。",
    date: "2021年6月1日",
  },
  {
    id: 1,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 4.png"),
    title: "网站+小程序+公众号结合，助力企业营销新模式",
    content:
      "互联网有很多产品，最早的时候，大家了解最多的就是网站，很多年前网站就是企业在互联网上的门面，而有了自媒体之后，很多企业都把自媒体当做企业面向用户的窗口，现在又有了短视频、视频直播、小程序等更多的产品。",
    date: "2021年6月1日",
  },
  {
    id: 3,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 5.png"),
    title: "微商行业如何利用小程序做营销？",
    content:
      "自从有微信以来，多出来了一个行业，那就是微商。虽然在前些年人们微商并不看好，甚至有点“讨厌”图片图片但这并不影响微商的发展，有些“微商人还”赚到了不少的收益图片图片。",
    date: "2021年6月1日",
  },
  {
    id: 4,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 5.png"),
    title: "物业公司开发小程序的效果杠杠的！",
    content:
      "现实生活中，很多行业都有了自己的微信小程序，比较常见的如餐饮行业，因为它有了与互联网相关的利器（小程序）之后，就可以充分利用微信本身庞大的群体资源啦！",
    date: "2021年6月1日",
  },
];

// for (let i = 0; i < 23; i++) {
//   listData.push({
//     href: "https://www.antdv.com/",
//     title: `ant design vue part ${i}`,
//     avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
//     description:
//       "Ant Design, a design language for background applications, is refined by Ant UED Team.",
//     content:
//       "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
//   });
// }

export default {
  data() {
    return {
      listData,
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 3,
      },
      actions: [
        { type: "star-o", text: "156" },
        { type: "like-o", text: "156" },
        { type: "message", text: "2" },
      ],
    };
  },
};
</script>

<style >
.ant-list-pagination {
  text-align: center !important;
}

.ant-list-split .ant-list-item {
  border-bottom: unset;
}

.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border-bottom: unset;
}
</style>