import axios from 'axios'
// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: 'https://wzcpc.gxxqy.cn/admin/',
    // baseURL: 'http://192.168.1.33:8089',
    timeout: 6000 // 请求超时时间
})

export default request

export {
    request as axios
}
