<template>
  <div>
    <a-row
      align="middle"
      type="flex"
      style="height: 200px; background-size: 100% 100%"
      :style="{ backgroundImage: 'url(' + contactBackgroundImage + ')' }"
    >
      <a-col :span="18" offset="3" style="height: 100%; position: relative">
        <div
          style="
            color: white;
            font-size: 35px;
            display: inline;
            position: absolute;
            top: 10%;
          "
        >
          行业资讯
        </div>
        <div
          style="
            color: white;
            font-size: 20px;
            display: inline;
            position: absolute;
            top: 50%;
          "
        >
          领先的数字化转型技术与服务提供商
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="22" offset="1">
        <a-row id="articleList">
          <a-col :span="18" offset="3" style="padding: 20px">
            <a-tabs style="text-align: center">
              <a-tab-pane key="1" tab="行业资讯">
                <articleListModule></articleListModule>
              </a-tab-pane>
              <a-tab-pane key="2" tab="公司动态">
                <articleListModule></articleListModule>
              </a-tab-pane>
            </a-tabs>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import articleListModule from "./articleListModule.vue";

export default {
  components: { articleListModule },
  data() {
    return {
      contactBackgroundImage: require("../../public/img/homepage/cutpicture/联系我们底图.png"),
    };
  },
};
</script>