<template>
  <div style="overflow: hidden">
    <!-- 顶部图片 -->
    <a-row
      align="middle"
      type="flex"
      style="height: 200px; background-size: 100% 100%"
      :style="{ backgroundImage: 'url(' + contactBackgroundImage + ')' }"
    >
      <a-col :span="18" offset="3" style="height: 100%; position: relative">
        <div
          style="
            color: white;
            font-size: 35px;
            display: inline;
            position: absolute;
            top: 10%;
          "
        >
          行业资讯
        </div>
        <div
          style="
            color: white;
            font-size: 20px;
            display: inline;
            position: absolute;
            top: 50%;
          "
        >
          领先的数字化转型技术与服务提供商
        </div>
      </a-col>
    </a-row>
    <!-- 顶部图片 -->

    <a-row :gutter="16" align="top" style="height: 100%">
      <a-col
        :span="18"
        offset="3"
        class="row-box row-box-height"
        style="background-color: white; padding: 30px"
      >
        <a-breadcrumb>
          <a-breadcrumb-item>
            <router-link
              :to="{
                name: '首页',
              }"
            >
              <a-icon type="home" />
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link
              :to="{
                name: fromPath,
                params: { fromPath },
              }"
              ><a-icon type="user" /> <span>{{ fromPath }}</span>
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> {{ articleTitle }} </a-breadcrumb-item>
        </a-breadcrumb>
        <div
          style="
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
          "
        >
          <div
            style="
              font-size: 25px;
              line-height: 30px;
              text-align: center;
              padding: 20px;
              color: #222;
              font-weight: 700;
              padding-left: 15px;
              padding-right: 15px;
            "
          >
            <div
              style="
                font-size: 25px;
                line-height: 30px;
                text-align: center;
                padding: 20px;
                color: #222;
                font-weight: 700;
              "
            >
              {{ articleTitle }}
            </div>

            <div style="flex-direction: row">
              <div style="border-bottom: 1px solid #eaeaea"></div>
            </div>
            <div
              style="
                color: #9a9a9a;
                font-size: 14px;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 10px;
                text-align: left;
                font-weight: lighter;
              "
            >
              发表时间：{{ articleCreatedate }} 来源：{{ articleAuthor }}
            </div>
          </div>
          <div
            style="margin: 0; padding: 0;h max-height: 75%; overflow-y: auto"
          >
            <div
              style="
                height: 100%;

                width: 90%;
                padding: 30px;
                margin-left: auto;
                margin-right: auto;
                text-align: justify;
              "
            >
              <div v-for="(item, index) in article" :key="index">
                <div
                  v-if="!item.absolutepath"
                  v-html="item.necoContent"
                  style="text-indent: 2em"
                ></div>
                <div
                  style="width: 100%; text-align: center"
                  v-if="item.absolutepath"
                >
                  <img
                    style="width: 50%; margin: 2em"
                    :src="item.absolutepath"
                  />
                </div>
                <div>
                  <!-- PDFJS -->
                  <div v-if="item.necoTexttype == 2" v-show="!finish">
                    正在读取……
                  </div>
                  <div
                    class="pdfh5"
                    style="width: 100%"
                    v-if="item.necoTexttype == 2"
                    v-show="finish"
                  >
                    <div :id="'pdf' + index"></div>
                  </div>
                  <!-- PDFJS -->
                </div>
              </div>
              <div style="height: 80px"></div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import request from "@/utils/request";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  data() {
    return {
      contactBackgroundImage: require("../../public/img/homepage/cutpicture/联系我们底图.png"),
      article: [
        {
          necoContent:
            "在当晚的讲话中，习近平主席表示，中方欢迎美方重返多边气候治理进程，期待同包括美方在内的国际社会一道，共同为推进全球环境治理而努力。重要基石：坚持共同但有区别的责任原则共同但有区别的责任原则，是全球气候治理的基石。习近平主席曾经打过一个比方：就像一场赛车一样，有的车已经跑了很远，有的车刚刚出发，这个时候用统一尺度来限制车速是不适当的，也是不公平的。他由此引申说，坚持共同但有区别的责任等原则，不是说发展中国家就不要为全球应对气候变化作出贡献了，而是说要符合发展中国家能力和要求",
        },
        {
          absolutepath:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimgwcs3.soufunimg.com%2Fnews%2F2019_03%2F21%2Fca4c1317-85dd-4183-9393-2509e97be639.jpg&refer=http%3A%2F%2Fimgwcs3.soufunimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1625211622&t=100505fb1bd693aed64f2ac973e692e5",
        },
      ],
      articleTitle: "默认标题",
      articleCreatedate: "2021年6月2日",
      articleAuthor: "管理员111",
      fromPath: "",
      pdfh5: null,
      finish: false,
    };
  },
  mounted() {
    console.log("articleid为:" + this.$route.params.articleid);
    this.fromPath = this.$route.params.fromPath;
    var that = this;
    request({
      url: "/newsInfo/getNewsInfoDetails",
      method: "GET",
      params: {
        id: this.$route.params.articleid,
      },
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res.data);
      that.article = res.data.data.newsContent;
      that.articleTitle = res.data.data.newsInfo.neinNewsname;
      that.articleCreatedate = res.data.data.newsInfo.neinCreatedate;
      that.articleAuthor = res.data.data.newsInfo.neinAuthor;
      //   res.data.data.forEach(function (value, index) {
      //     console.log(index);
      //     if (index == 4) {
      //       console.log(value);
      //       //"home_scroll_top"  图片，

      //       value.children.forEach(function (value2, index2) {
      //         console.log(value2, index2);
      //         var item = new Object();
      //         item.title = value2.neinNewsname;
      //         item.time = value2.neinStartdate;
      //         that.listData.push(item);
      //       });
      //     }
      //   });
      this.onloadPdf(res.data.data.newsContent);
    });
  },
  methods: {
    onloadPdf(article) {
      let that = this;
      article.forEach((element, index) => {
        if (element.necoTexttype == 2) {
          // Pdfh5使用
          request({
            url: element.absolutepath,
            method: "GET",
            // params: {
            //   id: this.$route.params.articleid,
            // },
            responseType: "arraybuffer",
          }).then((res) => {
            this.pdfh5 = new Pdfh5("#pdf" + index, {
              data: res.data,
            });
            // 监听完成事件;
            this.pdfh5.on("complete", function (status, msg, time) {
              that.finish = true;
              console.log(
                "状态：" +
                  status +
                  "，信息：" +
                  msg +
                  "，耗时：" +
                  time +
                  "毫秒，总页数：" +
                  this.totalNum
              );
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.ant-list-split .ant-list-item {
  border-bottom: unset;
}
</style>