<template>
  <div class="about" style="overflow: hidden">
    <!-- 上部概念banner -->
    <a-row style="position: relative; height: 2196px">
      <div
        style="height: 100%; width: 100%; position: absolute"
        :style="{
          backgroundImage: 'url(' + backgroundpic[0].pic + ')',
          backgroundSize: '100% 100%',
        }"
      ></div>
      <a-row style="height: 60%; width: 100%">
        <a-col :span="6" style="height: 100%">
          <div
            style="position: relative; height: 60rem; width: 100%; left: 12%"
          >
            <div
              style="
                z-index: 3;
                position: absolute;
                width: 100%;
                top: 60%;
                color: white;
                font-size: 40px;
                font-weight: border;
                white-space: normal;
                overflow-wrap: break-word;
                word-break: break-all;
                line-clamp: 2;
                float: left;
                text-align: left;
              "
            >
              新乾宇科技
            </div>
            <div
              style="
                border-bottom: 3px solid rgba(255, 255, 255, 1);
                width: 10%;
                top: 72%;
                z-index: 3;
                position: absolute;
              "
            ></div>
            <div
              style="
                position: absolute;
                width: 100%;
                top: 80%;
                z-index: 3;
                color: white;
                display: flex;
                float: left;
                text-align: left;
              "
            >
              <div
                style="
                  width: 80%;
                  font-size: 16px;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  line-height: 1.8;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 5;
                  overflow: hidden;
                "
              >
                凭借一流开发实力和丰富的行业服务经验，为企业提供优质服务
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="18" style="height: 100%">
          <div
            style="
              position: relative;
              height: 1034px;
              width: 1370px;
              top: 15%;
              float: right;
            "
          >
            <div
              style="
                position: absolute;
                height: 100%;
                width: 100%;
                color: white;
                left: 15px;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-wrap: wrap;
                "
              >
                <div
                  style="
                    z-index: 1;
                    width: 33.3%;
                    height: 25%;
                    position: relative;
                  "
                >
                  <div
                    style="
                      position: absolute;
                      left: 10%;
                      width: 90%;
                      height: 100%;
                    "
                  >
                    <div
                      style="
                        z-index: 3;
                        position: absolute;
                        width: 100%;
                        top: 20%;
                        color: white;
                        font-size: 20px;
                        font-weight: border;
                        white-space: normal;
                        overflow-wrap: break-word;
                        word-break: break-all;
                        line-clamp: 2;
                        float: left;
                        text-align: left;
                      "
                    >
                      办公环境
                    </div>
                    <div
                      style="
                        border-bottom: 3px solid rgba(255, 255, 255, 1);
                        width: 10%;
                        top: 38%;
                        z-index: 3;
                        position: absolute;
                      "
                    ></div>
                    <div
                      style="
                        position: absolute;
                        width: 100%;
                        top: 45%;
                        z-index: 3;
                        color: white;
                        display: flex;
                        float: left;
                        text-align: left;
                      "
                    >
                      <div
                        style="
                          width: 80%;
                          font-size: 16px;
                          word-break: break-all;
                          text-overflow: ellipsis;
                          overflow: hidden;
                          line-height: 1.8;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 5;
                          overflow: hidden;
                        "
                      >
                        新乾宇通讯科技总部位于南宁，全网价值营销之路全面开启并顺势延展，在许多行业都留下足迹。
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    z-index: -1;
                    width: 33.3%;
                    height: 25%;
                    mix-blend-mode: color;
                  "
                  :style="{ backgroundColor: backgroundColor(index) }"
                  v-for="(item, index) in 11"
                  :key="index"
                >
                  {{ index }}
                </div>
              </div>
            </div>
            <a-carousel
              autoplay
              autoplaySpeed="80"
              dotsClass="slick-dots"
              style="z-index: 0"
            >
              <div v-for="item in carouselpic" :key="item.id">
                <img
                  style="width: 100%"
                  :src="item.pic"
                  v-if="item.video == null"
                />
                <video
                  v-if="item.video != null"
                  width="1920"
                  height="978"
                  autoplay
                  loop
                  :poster="item.pic"
                  style="width: 1920px; top: 0; left: 0; object-fit: fill"
                >
                  <source :src="item.video" type="video/mp4" />
                  您的浏览器不支持Video标签。
                </video>
              </div>
            </a-carousel>
          </div></a-col
        >
      </a-row>
      <a-row>
        <a-col :span="12">
          <div style="height: 500px; width: 100%; position: relative">
            <img
              style="position: absolute; left: 10%; top: 50%"
              :src="backgroundpic[2].pic"
            />
            <div
              style="position: absolute; left: 10%; width: 90%; height: 100%"
            >
              <div
                style="
                  z-index: 3;
                  position: absolute;
                  width: 100%;
                  top: 30%;
                  color: white;
                  font-size: 20px;
                  font-weight: border;
                  white-space: normal;
                  overflow-wrap: break-word;
                  word-break: break-all;
                  line-clamp: 2;
                  float: left;
                  text-align: left;
                "
              >
                广西新乾宇通讯科技有限公司
              </div>

              <div
                style="
                  position: absolute;
                  width: 100%;
                  top: 45%;
                  z-index: 3;
                  color: white;
                  display: flex;
                  float: left;
                  text-align: left;
                "
              >
                <div
                  style="
                    width: 80%;
                    font-size: 16px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    line-height: 1.8;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 8;
                    overflow: hidden;
                    line-height: 1;
                  "
                  v-html="infocontent"
                >
                  创立于1997年，是由一支高新技术团队创立的创新型企业，总部位于南宁。主要从事软件定制开发、硬件生产、大数据平台建设，是信息化系统集成、通讯设备工程、计算机网络技术、安全技术防范、智能化建设、外国政府贷款等多元化业务的服务领跑者，致力于为党政相关部门、企业、公检法司等客户提供全方位的解决方案，并实现科技与政企管理、教育、文化等行业的紧密融合。长期以来，新乾宇作为高新技术型产业，始终在创新引领的道路上奋勇前行。在面对新基建时代发展的必然趋势下，为顺应科技与各行业融合的时代潮流，新乾宇紧跟国家政策及产业变革步伐，积极开拓新业务，全面引进云计算与大数据、人工智能、软件定制、网络安全及等保、运维服务等综合业务板块，以求实现技术更迭及产业升级。目前，已完成智慧门牌、智慧市场、智慧交通、智慧校园、智慧城市、智能制造、智能医疗等多种应用场景，为助推广西经济快速发展贡献自己的力量！
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <img
            style="position: absolute; right: 0"
            :src="backgroundpic[1].pic"
          />
        </a-col>
      </a-row>
    </a-row>
    <!-- 上部概念banner -->
    <a-row
      :gutter="16"
      align="top"
      style="height: 30px; background-color: rgba(0, 0, 0, 0.1)"
    ></a-row>
    <!-- 我们的成果 -->
    <a-row
      id="info"
      :gutter="16"
      align="top"
      class="row-box-height"
      style="position: relative; background-color: rgba(0, 0, 0, 0.1)"
    >
      <a-col :span="3"></a-col>
      <a-col :span="18">
        <a-row style="margin-bottom: 20px">
          <div
            style="
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:40px;
              font-weight:border;
              color:black;
            "
          >
            我们的成果
          </div>
          <a-col :span="12" :offset="6">
            <div
              style="
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:16px;
            "
            >
              为您提供创新的互联网解决方案
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-bottom: 30px">
          <a-carousel
            autoplay
            autoplaySpeed="80"
            arrows
            dotsClass="slick-dots1"
            data-v-f3f3nw9
          >
            <div
              slot="prevArrow"
              class="custom-slick-arrow"
              style="left: -7%; z-index: 9; color: black"
            >
              <img src="../../public/img/homepicture/首页/组 1070.png" />
            </div>
            <div
              slot="nextArrow"
              class="custom-slick-arrow"
              style="right: -7%; z-index: 9; color: black"
            >
              <img src="../../public/img/homepicture/首页/组 1071.png" />
            </div>
            <div v-for="(item1, index1) in listTemp" :key="index1">
              <div style="width: 100%; height: 100%">
                <a-row :gutter="[16, 16]" align="top" style="">
                  <a-col
                    :span="6"
                    v-for="(item2, index2) in item1"
                    :key="index2"
                    :id="index1 + index2"
                    style="height: 530px"
                  >
                    <div style="display: contents">
                      <a-card
                        :bordered="false"
                        style="
                          width: 100%;
                          max-height: 100%;
                          text-align: left;
                          background-color: transparent;
                        "
                      >
                        <img
                          slot="cover"
                          alt="example"
                          :src="item2.pic"
                          style="object-fit: contain; max-height: 460px"
                        />
                        <a-card-meta>
                          <!-- <template slot="title">
                    <div
                      style="
                        width: 100%;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      "
                    >
                      {{ item2.title }}
                    </div>
                  </template> -->
                          <template slot="description">
                            <div
                              style="
                                width: 100%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                font-weight: bolder;
                                color: black;
                              "
                            >
                              {{ item2.content }}
                            </div>
                          </template>
                        </a-card-meta>
                      </a-card>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-carousel>
        </a-row>
      </a-col>
      <a-col :span="3"></a-col>
    </a-row>
    <!-- 我们的成果 -->
    <a-row :gutter="16" align="top" style="height: 30px"></a-row>
    <!-- 行业资讯 -->
    <a-row
      id="info"
      :gutter="16"
      align="top"
      class="row-box-height"
      style="position: relative"
    >
      <a-col :span="3"></a-col>
      <a-col :span="18">
        <a-row style="margin-bottom: 20px">
          <div style="position: relative; height: 300px; width: 100%">
            <div
              style="
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:35px;
              font-weight:border;
              color:black;
              position: absolute;
              top:15%;
            "
            >
              行业资讯
            </div>
            <div
              style="
                border-bottom: 3px solid rgba(0, 0, 0, 1);
                width: 10%;
                top: 45%;
                position: absolute;
              "
            ></div>
            <div
              style="
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:16px;
              position: absolute;
              top:50%
            "
            >
              利用行业资讯，指定精准营销策略，连接未来
            </div>
            <a-button style="right: 0; position: absolute; top: 35%"
              >查看更多</a-button
            >
          </div>
        </a-row>
        <a-row type="flex" justify="space-around" align="middle">
          <a-col :span="7" v-for="item in hangyezixunbag" :key="item.id">
            <div style="padding: 30px; height: 208px; width: 486px">
              <a-card hoverable :bordered="true" style="width: 100%">
                <a-card-meta>
                  <template slot="title">
                    <div
                      style="
                        font-weight: 900;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      "
                    >
                      {{ item.title }}
                    </div>
                  </template>
                  <template slot="description">
                    <div
                      style="
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      "
                    >
                      {{ item.content }}
                    </div>
                  </template>
                </a-card-meta>
              </a-card>
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="space-around"
          align="middle"
          style="margin: 30px"
        >
          <a-button>查看更多</a-button>
        </a-row>
      </a-col>
      <a-col :span="3"></a-col>
    </a-row>

    <!-- 行业资讯 -->
    <footer1></footer1>
  </div>
</template>

<script>
/* 底图使用数据 */
const backgroundpic = [
  {
    id: 0,
    pic: require("../../public/img/homepicture/关于我们/关于我们.png"),
  },
  {
    id: 1,
    pic: require("../../public/img/homepicture/关于我们/图像 95.png"),
  },
  {
    id: 2,
    pic: require("../../public/img/homepicture/关于我们/About.png"),
  },
];

/* 轮播图使用数据 */
const carouselpic = [
  {
    id: 0,
    pic: require("../../public/img/homepicture/关于我们/1.png"),
  },
  {
    id: 1,
    pic: require("../../public/img/homepicture/关于我们/2.png"),
    // video: require("../../public/img/homepicture/关于我们/1.mp4"),
  },
  {
    id: 2,
    pic: require("../../public/img/homepicture/关于我们/3.png"),
  },
];

const infocontent =
  "<p>是一家成立于1997年的科技型法人企业，</p><p>致力于无线通讯、数据中心机房建设、系统集成与智能化、智能交通、平安城市、国际贷款、</p><p>软件定制等业务，公司注册资金3000万元，拥有1000多平方米办公场地。</p><p>是ISO9001：2008认证企业、安防工程企业资质二级、信息系统集成资质（三级）</p><p>涉密信息系统集成资质（乙级）、广西安全技术防范协会会员，拥有30多项软件著作权。</p><p>公司与国内外知名信息化厂家建立了良好的合作关系，公司秉承“真诚合作、携手共赢”的理念，</p><p>坚持“顾客及上帝”的经营方针，成立以来，已在政府、公安、法院、检察院</p><p>教育、林业、司法、电信运营商等行业拥有广泛的合作伙伴。</p>";

/* 专业资质 */
const qualification = [
  {
    id: 1,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（1）.png"),
    title: "软件著作权登记证书（1）",
    content: "软件著作权登记证书（1）",
  },
  {
    id: 2,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（2）.png"),
    title: "软件著作权登记证书（2）",
    content: "软件著作权登记证书（2）",
  },
  {
    id: 3,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（3）.png"),
    title: "软件著作权登记证书（3）",
    content: "软件著作权登记证书（3）",
  },
  {
    id: 4,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（4）.png"),
    title: "软件著作权登记证书（4）",
    content: "软件著作权登记证书（4）",
  },
  {
    id: 5,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（5）.png"),
    title: "软件著作权登记证书（5）",
    content: "软件著作权登记证书（5）",
  },
  {
    id: 6,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（6）.png"),
    title: "软件著作权登记证书（6）",
    content: "软件著作权登记证书（6）",
  },
  {
    id: 7,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（7）.png"),
    title: "软件著作权登记证书（7）",
    content: "软件著作权登记证书（7）",
  },
  {
    id: 8,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（8）.png"),
    title: "软件著作权登记证书（8）",
    content: "软件著作权登记证书（8）",
  },
  {
    id: 9,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（9）.png"),
    title: "软件著作权登记证书（9）",
    content: "软件著作权登记证书（9）",
  },
  {
    id: 10,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（10）.png"),
    title: "软件著作权登记证书（10）",
    content: "软件著作权登记证书（10）",
  },
  {
    id: 11,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（11）.png"),
    title: "软件著作权登记证书（11）",
    content: "软件著作权登记证书（11）",
  },
  {
    id: 12,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（12）.png"),
    title: "软件著作权登记证书（12）",
    content: "软件著作权登记证书（12）",
  },
  {
    id: 13,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（13）.png"),
    title: "软件著作权登记证书（13）",
    content: "软件著作权登记证书（13）",
  },
  {
    id: 14,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（14）.png"),
    title: "软件著作权登记证书（14）",
    content: "软件著作权登记证书（14）",
  },
  {
    id: 15,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（15）.png"),
    title: "软件著作权登记证书（15）",
    content: "软件著作权登记证书（15）",
  },
  {
    id: 16,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（16）.png"),
    title: "软件著作权登记证书（16）",
    content: "软件著作权登记证书（16）",
  },
  {
    id: 17,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（17）.png"),
    title: "软件著作权登记证书（17）",
    content: "软件著作权登记证书（17）",
  },
  {
    id: 18,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（18）.png"),
    title: "软件著作权登记证书（18）",
    content: "软件著作权登记证书（18）",
  },
  {
    id: 19,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（19）.png"),
    title: "软件著作权登记证书（19）",
    content: "软件著作权登记证书（19）",
  },
  {
    id: 20,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（20）.png"),
    title: "软件著作权登记证书（20）",
    content: "软件著作权登记证书（20）",
  },
  {
    id: 21,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（21）.png"),
    title: "软件著作权登记证书（21）",
    content: "软件著作权登记证书（21）",
  },
  {
    id: 22,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（22）.png"),
    title: "软件著作权登记证书（22）",
    content: "软件著作权登记证书（22）",
  },
  {
    id: 23,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（23）.png"),
    title: "软件著作权登记证书（23）",
    content: "软件著作权登记证书（23）",
  },
  {
    id: 24,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（24）.png"),
    title: "软件著作权登记证书（24）",
    content: "软件著作权登记证书（24）",
  },
  {
    id: 25,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（25）.png"),
    title: "软件著作权登记证书（25）",
    content: "软件著作权登记证书（25）",
  },
  {
    id: 26,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（26）.png"),
    title: "软件著作权登记证书（26）",
    content: "软件著作权登记证书（26）",
  },
  {
    id: 27,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（27）.png"),
    title: "软件著作权登记证书（27）",
    content: "软件著作权登记证书（27）",
  },
  {
    id: 28,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（28）.png"),
    title: "软件著作权登记证书（28）",
    content: "软件著作权登记证书（28）",
  },
  {
    id: 29,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（29）.png"),
    title: "软件著作权登记证书（29）",
    content: "软件著作权登记证书（29）",
  },
  {
    id: 30,
    pic: require("../../public/img/homepicture/关于我们/certificate/软件著作权登记证书（30）.png"),
    title: "软件著作权登记证书（30）",
    content: "软件著作权登记证书（30）",
  },
  {
    id: 31,
    pic: require("../../public/img/homepicture/关于我们/certificate/营业执照.png"),
    title: "营业执照",
    content: "营业执照",
  },
  {
    id: 32,
    pic: require("../../public/img/homepicture/关于我们/certificate/企业信用AAA等级证书.png"),
    title: "企业信用AAA等级证书",
    content: "企业信用AAA等级证书",
  },
  {
    id: 33,
    pic: require("../../public/img/homepicture/关于我们/certificate/涉密信息系统集成乙级资质证书.png"),
    title: "涉密信息系统集成乙级资质证书",
    content: "涉密信息系统集成乙级资质证书",
  },
  {
    id: 34,
    pic: require("../../public/img/homepicture/关于我们/certificate/安防工程企业设计施工维护能力证书.png"),
    title: "安防工程企业设计施工维护能力证书",
    content: "安防工程企业设计施工维护能力证书",
  },
  {
    id: 35,
    pic: require("../../public/img/homepicture/关于我们/certificate/信息系统集成叁级能力证书.png"),
    title: "信息系统集成叁级能力证书",
    content: "信息系统集成叁级能力证书",
  },
  {
    id: 36,
    pic: require("../../public/img/homepicture/关于我们/certificate/信息系统运行维护能力叁级证书.png"),
    title: "信息系统运行维护能力叁级证书",
    content: "信息系统运行维护能力叁级证书",
  },
  {
    id: 37,
    pic: require("../../public/img/homepicture/关于我们/certificate/电子与智能化工程专业承包贰级资质证书.png"),
    title: "电子与智能化工程专业承包贰级资质证书",
    content: "电子与智能化工程专业承包贰级资质证书",
  },
  {
    id: 38,
    pic: require("../../public/img/homepicture/关于我们/certificate/环境管理体系认证证书（英文版）.png"),
    title: "环境管理体系认证证书（英文版）",
    content: "环境管理体系认证证书（英文版）",
  },
  {
    id: 39,
    pic: require("../../public/img/homepicture/关于我们/certificate/环境管理体系认证证书.png"),
    title: "环境管理体系认证证书",
    content: "环境管理体系认证证书",
  },
  {
    id: 40,
    pic: require("../../public/img/homepicture/关于我们/certificate/职业健康安全管理体系认证证书（英文版）.png"),
    title: "职业健康安全管理体系认证证书（英文版）",
    content: "职业健康安全管理体系认证证书（英文版）",
  },
  {
    id: 41,
    pic: require("../../public/img/homepicture/关于我们/certificate/职业健康安全管理体系认证证书.png"),
    title: "职业健康安全管理体系认证证书",
    content: "职业健康安全管理体系认证证书",
  },
  {
    id: 42,
    pic: require("../../public/img/homepicture/关于我们/certificate/质量管理体系认证证书（英文版）.png"),
    title: "质量管理体系认证证书（英文版）",
    content: "质量管理体系认证证书（英文版）",
  },
  {
    id: 43,
    pic: require("../../public/img/homepicture/关于我们/certificate/质量管理体系认证证书.png"),
    title: "质量管理体系认证证书",
    content: "质量管理体系认证证书",
  },
  {
    id: 44,
    pic: require("../../public/img/homepicture/关于我们/certificate/安全生产许可证.png"),
    title: "安全生产许可证",
    content: "安全生产许可证",
  },
];

/* 行业资讯 */
const hangyezixunbag = [
  {
    id: 0,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 3.png"),
    title: "智能化建设",
    content:
      "各个环节以“线上+线下”结合的形式进行智能化管理；多个智能化系统降低管理成本、提升管理高度。",
  },
  {
    id: 1,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 4.png"),
    title: "云计算与大数据",
    content:
      "大数据和云计算这两种技术本身都是有价值的。 此外，许多企业的目标是将两种技术结合起来以获取更多的商业利益。两种技术都旨在提高公司的收入，同时降低投资成本。尽管Cloud管理本地软件，但大数据有助于业务决策。",
  },
  {
    id: 2,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 5.png"),
    title: "App定制开发",
    content:
      "每一款app软件之所以有不同的亮点，一方面来自于功能的独特性，而另外一个方面则是因为界面的不同，这两点中对用户影响比较直观的就是app界面的设计，对于APP开发投资者来说，APP的功能完善，同样界面的设计和风格有自己的特点，这样app软件是独一无二的。所以极力推荐企业选择。",
  },
];

import footer1 from "../views/footer.vue";

export default {
  components: { footer1 },
  data() {
    return {
      backgroundpic,
      carouselpic,
      qualification,
      hangyezixunbag,
      infocontent,
    };
  },
  methods: {
    backgroundColor(index) {
      switch (index) {
        case 0:
          return "blue";
        case 4:
          return "blue";
        case 5:
          return "purple";
        // default:
        //   return "gray";
      }
    },
  },
  computed: {
    // 分页计算，多组
    listTemp: function () {
      var list = this.qualification;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 8;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    },
  },
};
</script>

<style scoped>
.ant-carousel >>> .slick-slider {
  z-index: -5;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel >>> .slick-dots {
  display: none !important;
  height: auto;
  left: 13%;
  margin: 0px auto;
  text-align: left;
  bottom: 110px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-slider {
  z-index: unset;
}
.ant-carousel[data-v-f3f3nw9] >>> .slick-slide {
  z-index: unset !important;
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: transparent;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots1 {
  height: auto;
  margin: 0px auto;
  text-align: center;
  bottom: -30px;
  left: 0;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots li button {
  display: block;
  width: 32px;
  height: 6px;
  padding: 0;
  color: black !important;
  font-size: 0;
  background: black !important;
  border: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  opacity: 0.1;
  transition: all 0.5s;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots li.slick-active button {
  color: black !important;
  width: 48px;
  background: black !important;
  opacity: 1;
}

.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-slide h3 {
  color: #fff;
}
</style>